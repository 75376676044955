<template>
  <b-card
    class="stockbase-edit-wrapper"
  >
    <!-- form -->
    <b-form id="stockbaseForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="stockbase.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="stockbase.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="stockbase.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存编号"
            label-for="stock_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_no"
              size="sm"
              v-model="stockbase.stock_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="stockbase.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="stockbase.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否赠品"
            label-for="is_gift"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_gift"
              size="sm"
              v-model="stockbase.is_gift"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="stockbase.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="stockbase.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="stockbase.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购主体"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="stockbase.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单明细ID"
            label-for="purchaseorder_ItemId"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchaseorder_ItemId"
              size="sm"
              v-model="stockbase.purchaseorder_ItemId"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单含税单价"
            label-for="cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost"
              size="sm"
              v-model="stockbase.cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓位ID"
            label-for="location_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="location_id"
              size="sm"
              v-model="stockbase.location_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓位全称"
            label-for="location_fullname"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="location_fullname"
              size="sm"
              v-model="stockbase.location_fullname"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="stockbase.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="锁库数量"
            label-for="qty_lock"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty_lock"
              size="sm"
              v-model="stockbase.qty_lock"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 stock_status"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="stockbase.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="stockbase.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="stockbase.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="stockbase.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="stockbase.updator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="货物主体"
            label-for="product_company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_company_id"
              size="sm"
              v-model="stockbase.product_company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单ID"
            label-for="purchaseorder_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchaseorder_id"
              size="sm"
              v-model="stockbase.purchaseorder_id"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import stockbaseStore from './stockbaseStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      stockbase: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stockbase')) store.registerModule('stockbase', stockbaseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockbase')) store.unregisterModule('stockbase')
    })

    const edit = function() {
      store.dispatch('stockbase/edit', {id: this.id}).then(res => {
        this.stockbase = res.data.data
      })
    }

    const view = function() {
      store.dispatch('stockbase/view', {id: this.id}).then(res => {
        this.stockbase = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('stockbase/save', this.stockbase).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>